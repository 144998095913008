//import { useContext}        from 'react';
//import { Context_User }     from '@/context/Context_User_Provider';
import { iUser }            from '@/libs/interfaces';


//inicialmente parecia mejor usar esto para evitar definir useContext en cada function
//pero al tener que usar (ctx_User && ctx_User.COD_CLTE);
//creo que es mejor definir useContext en cada function
//function get_ctx_User_obsoleta() {
//    const { ctx_User } = useContext(Context_User);
//    return ctx_User;
//    //return useContext(ctx_User) // esta forma de hacerlo da error
//    }
//esto era un intento de integrar aqui el acceso a useContext pero no es posible siempre asi que 
//opto por pasarlo por parametro a cada function que es mas fácil

export function sub_ryc_DDBB_Data() {;
    //const { ctx_User } = useContext(Context_User); //quito esto ya que lo paso por parametro
    //return "Temporadas(" + get_ctx_User().COD_CLTE + ")";
    return 'DDBB_Data()';
    }

export function sub_ryc_Temporadas(pContextUser: iUser) {;
    //const { ctx_User } = useContext(Context_User); //quito esto ya que lo paso por parametro
    //return "Temporadas(" + get_ctx_User().COD_CLTE + ")";
    //sub Temporadas_v2 (prmtrCodClte                     , prmtrWhere, prmtrOrderby                , prmtrTop, prmtrMostrarCtosInhabilitados)'
    //return 'Temporadas_v2(' + (pContextUser.COD_CLTE)  + ',null' +   ',"ORDER BY COD_TEMPORADA DESC",null' + ',true)';
    return 'Temporadas_v3(' + (pContextUser.COD_CLTE) + ',null,"ORDER BY COD_TEMPORADA DESC",null' + ',true,' + (pContextUser.COD_USUARIO) + ')';
    }

export function sub_ryc_Zonas(pContextUser: iUser) {
    //const { ctx_User } = useContext(Context_User);
    //sub Provincias (prmtrCodClte, prmtrCodTemporada)
    return 'Zonas_v1(' + (pContextUser.COD_CLTE)      + ',' 
                       + (pContextUser.COD_TEMPORADA) + ',' 
                       + (pContextUser.COD_USUARIO) 
                       + ')';
    }

export function sub_ryc_Campeonato(pContextUser: iUser) {
    //const { ctx_User } = useContext(Context_User);
    //sub Campeonatos (prmtrCodClte, prmtrCodTemporada, prmtrCodZona)
    return 'Campeonato(' + (pContextUser.COD_CAMPEONATO) + ')';
    }

export function sub_ryc_Campeonatos(pContextUser: iUser) {
    //const { ctx_User } = useContext(Context_User);
    //sub Campeonatos (prmtrCodClte, prmtrCodTemporada, prmtrCodZona)
    /* return 'Campeonatos(' + (pContextUser.COD_CLTE)      + ',' 
                          + (pContextUser.COD_TEMPORADA) + ',' 
                          + (pContextUser.COD_ZONA)      + ')'; */
    //sub Campeonatos_v4 (prmtrCodClte, prmtrCodTemporada, prmtrCodZona, prmtrBusquedaNombreEquipo, prmtrCodTorneoCircuito, prmtrCodUsuario, prmtrMostrarCtosInhabilitados)
    return 'Campeonatos_v4(' + (pContextUser.COD_CLTE)      + ',' 
                             + (pContextUser.COD_TEMPORADA) + ',' 
                             + (pContextUser.COD_ZONA)      + ',' 
                             + 'null,null,' 
                             + (pContextUser.COD_USUARIO)      + ','  //+ '0,' //esto es lo que estabq antes de pasar el COD_USUARIO
                             + 'true)';
    }

export function sub_ryc_Equipos(pContextUser: iUser) {
    //sub Equipos (prmtrCodCampeonato, prmtrCodGrupo, prmtrCodUsuario)
    return 'Equipos(' + pContextUser.COD_CAMPEONATO + ',' 
                    //+ (pContextUser && pContextUser.COD_GRUPO) + ',' //POR AHORA  NO HAY GRUPOS
                      + 'null,null)';
    }

export function sub_ryc_Equipos_x_Clubs(pContextUser: iUser) {
    //sub Equipos_x_Clubs (prmtrCodCampeonato)
    const _return = 'Equipos_x_Clubs(' + pContextUser.COD_CLTE + ', ' + pContextUser.COD_CAMPEONATO + ')';
    //console.log ('sub_ryc_Equipos_x_Clubs: ' + _return);
    return _return;
    }

export function sub_ryc_Jugadores(pContextUser: iUser) {
    //sub Jugadores (prmtrCodCampeonato, prmtrCodEquipo, prmtrOrderBy)
    return 'Jugadores2(' + pContextUser.COD_CAMPEONATO + ',' 
                        + pContextUser.COD_EQUIPO      + ',' 
                        //+ '" ORDER BY NOMBRE_JUGADOR, APELLIDO1_JUGADOR, APELLIDO2_JUGADOR" ' 
                        + '" ORDER BY NOMBRE_COMPLETO_JUGADOR" ' 
                        + ')';
    }

export function sub_ryc_Delegados(pContextUser: iUser) {
    //sub Delegados3 (prmtrCodCampeonato, prmtrCodGrupo, prmtrCodEquipo, prmtrOrderBy)
    return 'Delegados3(' + pContextUser.COD_CAMPEONATO + ', null, ' 
                         + pContextUser.COD_EQUIPO     + ', '
                         + ' "ORDER BY NOMBRE_COMPLETO_DELEGADO"'
                         + ')';
    }

export function sub_ryc_Acompaniantes(pContextUser: iUser) {
    //sub Acompaniantes (prmtrCodCampeonato, prmtrCodEquipo, prmtrCodAcompaniante, prmtrOrderBy)
    return 'Acompaniantes(' + pContextUser.COD_CAMPEONATO + ',' 
                            + pContextUser.COD_EQUIPO     + ','
                            + 'null, '  
                            + '"ORDER BY NOMBRE_COMPLETO_ACOMPANIANTE" ' 
                            + ')';
    }


export function sub_ryc_Clubs(pContextUser: iUser) {
    return 'Clubs(' + pContextUser.COD_TEMPORADA + ',' 
                    + pContextUser.COD_CLTE      + ')';
    }

export function sub_ryc_Existe_Jugador(prmtrCodCampeonato: number, prmtrDNIJugador: string, prmtrNombreJugador: string, prmtrApellido1Jugador: string, prmtrApellido2Jugador: string) {;
    //Crear_Consulta_Existe_Jugador (prmtrCodCampeonato, prmtrDNIJugador, prmtrNombreJugador, prmtrApellido1Jugador, prmtrApellido2Jugador)
    return 'Existe_Jugador(' + prmtrCodCampeonato + ',"' + prmtrDNIJugador + '","' + prmtrNombreJugador + '","' + prmtrApellido1Jugador + '","' + prmtrApellido2Jugador + '")';
    }