'use client'

import { console_log } from '@/libs/debug';
import { createContext} from 'react';
import { useState } from 'react';

import { iFlags } from '@/libs/interfaces'

/*
Un ejemplo de como se hace...
https://www.js-craft.io/blog/using-react-context-nextjs-13/
*/

//------------------------------------------------------
// 1) Crear el contexto
//inicializo los valores
const default_context_Flags: iFlags = {
    room                        : undefined,
    campeonato                  : undefined,
    equipo                      : undefined,
    club                        : undefined,
    accommodation_occupancy     : undefined,
    person                      : {COD_EQUIPO: undefined, COD_JUGADOR: undefined, COD_DELEGADO: undefined, COD_ACOMPANIANTE: undefined},
    drawer_open                 : false,
    tree_modified               : false, // ¿ by user ?
    tree_loading_championships  : false,
    };

interface iContext_Flags {
    ctx_Flags   : iFlags;
    sctx_Flags: React.Dispatch<React.SetStateAction<iFlags>>;
    }
export const Context_Flags = createContext<iContext_Flags>({
    //ctx_User: {} as iUser,
    ctx_Flags: default_context_Flags,
    sctx_Flags: () => {}, // Asignamos una función vacía como valor inicial
    });

//------------------------------------------------------
// 2) Crear el componente que provee el contexto

type Props = {
    children: React.ReactNode,
    pContext_Flags?: iFlags,
    }
export const Context_Flags_Provider = ({ children, pContext_Flags}: Props) => {
    //const [ctx_User, sctx_User] = useState<iUser>(initialUser);
    const [ctx_Flags, sctx_Flags] = useState<iFlags>(pContext_Flags || default_context_Flags);
    return (
        <Context_Flags.Provider value={{ ctx_Flags, sctx_Flags}}>
            <>
            {/* {console_log (['Context_User_Provider > ctx_User', ctx_Flags], "ctx Flags")} */}
            {children}
            </>
        </Context_Flags.Provider>
        );
    };

