import { console_log } from "@/libs/debug"

export async function get_JSON(pServerFunction: string) {
    try {
        const url_path_JSON = process.env.NEXT_PUBLIC_URL! + process.env.NEXT_PUBLIC_PATH_JSON! + pServerFunction;
        const res = await fetch(url_path_JSON)
        if (!res.ok) {
            var error_desc = "Error getting JSON from...\n" + url_path_JSON + "\nstatus: " + res.status + "\nstatusText: " + res.statusText
            throw new Error(error_desc)
            }
        
        const res_json = await res.json()
        return res_json
        }
    catch (error) {
        // Manejo de errores
        console.error("Error in get_JSON:", error);
        throw error; // Lanza el error para que sea manejado en el nivel superior
        }
    //return res.json()
    }

//2024_11_15
//funcion para hacer una llamada a la base de datos desde el servidor
// ¿ seguro que la anterior lo hace desde cliente ?
export async function get_JSON_in_SERVER (pFunction: string) {
    const url_path_JSON = process.env.NEXT_PUBLIC_URL! + process.env.NEXT_PUBLIC_PATH_JSON! + pFunction;

    //const response = await fetch(url);

    /* if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        } */

    //return response.json(); // Procesa y devuelve el JSON para ser usado en TryCatch_SERVER
    };
