'use client'
import React, { useState, useContext, useEffect }       from 'react';
import Link                                             from 'next/link';
import Box                                              from '@mui/material/Box';
import AppBar                                           from '@mui/material/AppBar';
import Toolbar                                          from '@mui/material/Toolbar';
import IconButton                                       from '@mui/material/IconButton';
import Button                                           from '@mui/material/Button';
import Typography                                       from '@mui/material/Typography';
import ThemeProvider                                    from '@mui/material/styles/ThemeProvider';
import { useTheme }                                     from '@mui/material/styles'; 
import useMediaQuery                                    from '@mui/material/useMediaQuery';
import { cloneDeep }                                    from 'lodash-es';

import { useSession, signIn }                           from 'next-auth/react';
import { SessionProvider }                              from 'next-auth/react';

import { Context_Flags_Provider, Context_Flags }        from '@/context/Context_Flags_Provider';
import { Context_User_Provider, Context_User }          from '@/context/Context_User_Provider';

import { useRouter }                                    from 'next/navigation';


import                                                 '@/styles/globals.css';
import mui_theme                                        from '@/styles/mui_theme';
import * as icons                                       from '@/styles/icons';

import { getFileName, isDevelopmentDebug }                   from '@/libs/general';
import { get_JSON }                         from '@/libs/json';
import { sub_ryc_DDBB_Data }                            from '@/libs/json_subs_ryc';
import { TryCatch_CLIENT_with_api }                     from '@/libs/db_prisma';
import { iFormController_body }                         from '@/libs/interfaces'
import DebugBox                                         from '@/components/DebugBox';
import { Copy_from_authSession_to_ctxUser }             from '@/libs/auth';



interface Props {
   children: React.ReactNode;
   session: any;
   }
//para poder usar correctametne el SessionProvider de next-auth/react y que este disponble sus propiedades
//- envuelvo en ete primer nivel LayoutClient con session
//- creo aqui un subcomponente LayoutClient2 que es el que realmente contiene el contenido de la pagina y que puede acceder a las propiedades de session
export default function LayoutClient({ children, session }: Props) {
    return (
        <SessionProvider session={session}>
            <Context_Flags_Provider >
            <Context_User_Provider >
                <ThemeProvider theme={mui_theme}>
                    <LayoutClient2>{children}</LayoutClient2>
                </ThemeProvider>
            </Context_User_Provider>
            </Context_Flags_Provider>
        </SessionProvider>
        );
    }


const privated_routes = ['/dashboard', '/profile', /* otras rutas protegidas */];

function LayoutClient2 ({ children }: { children: React.ReactNode }) {
    const { ctx_User, sctx_User } = useContext(Context_User);
    const { ctx_Flags, sctx_Flags } = useContext(Context_Flags);

    const [stt_DDBB_ryc, sstt_DDBB_ryc] = useState("");
    const [stt_DDBB_prisma, sstt_DDBB_prisma] = useState("");
    const [stt_isTest, sstt_isTest] = useState(false);
    //const [stt_DrawerOpen, sstt_DrawerOpen] = useState(false);
    //const [stt_client_name, sstt_client_name] = useState("");

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(
                            theme.breakpoints.down('md'), //md: 960px
                            {noSsr: true} //evita que se ejecute en el servidor y asi intento evitar errores que se daban con turbopack -> NO ERA ESTO, sigue fallando en turbo
                            ); 

    const { data: session, status } = useSession(); //nextAuth: para saber si el usuario está logueado
    const router = useRouter();

    //----------------------------------------------------------------------
    // Lo necesario para que se logeee autamáticamente en desarrollo
    // * he quitado la redirección a dashboard porque corrompia el funcionamiento de la app
    //he comentado lo siguiente para ver si era lo que generaba problemas con turbopack pero nada, 
    //no obstante lo dejo comentado porque estaba inhabilitado con el false y no sea que produzca efectos colaterales no controlados.
    /* useEffect(() => {
        if (isDevelopmentDebug() && false) { //con este false lo inhabilito
            if (status === 'unauthenticated') {
                // Iniciar sesión automáticamente en desarrollo
                signIn('credentials', {
                        //email: process.env.NEXT_PUBLIC_DEV_EMAIL,
                        //password: process.env.NEXT_PUBLIC_DEV_PASSWORD,
                        email: "admpcdc",
                        password: "admpcdc",
                        redirect: false,
                        }).then(() => {
                                        //router.push('/dashboard');
                                        });
                } 
            else if (session && status === 'authenticated') {
                // Redirigir a dashboard si ya está autenticado
                //router.push('/dashboard');
                }            
            }
        //}, [session, status, router]);
        }, []); */

    //----------------------------------------------------------------------
    useEffect(() => {
        //get_JSON(sub_ryc_DDBB_Data()).then((DDBB_Data) => {
            const fetch_DDBB_Data = async () => {


                const isTest = process.env.NEXT_PUBLIC_URL?.includes('test.') ?? false;
                sstt_isTest(isTest);

                //ahora mismo este proyecto se contecta a la base de datos mediante consultas:
                //...ejecutasdas por ryc en el codigo asp de la versión orginal de ryc
                //...ejecutadas por prisma, aqui mismo, en el codigo de este proyecto
                //a continuación lo que hago es hayar a que instancia y BBDD se contectan cada una
                //para mas poder verlo en la parte superior de la pantalla y evitar posibles errores
                const sub_ryc = sub_ryc_DDBB_Data();
                const response_ryc = await get_JSON(sub_ryc);
                let str_DDBB = ""
                if (response_ryc[0]) {
                    //sstt_DDBB_ryc(response_ryc[0]?.INSTANCE + " - " + getFileName(response_ryc[0]?.FILENAME));
                    if (!isTest) {str_DDBB = response_ryc[0].INSTANCE + " - "}
                    str_DDBB += getFileName(response_ryc[0].FILENAME);
                    sstt_DDBB_ryc(str_DDBB);
                    }
    
                const request = {
                                function_name: "get_Instance_and_DDBB_File",
                                }
                let response_prisma = await TryCatch_CLIENT_with_api('/api/prisma', request);
                //console.log("RESPONSE response_prisma 0...", response_prisma);
                if (response_prisma?.extended_response?.response[0]) {
                    response_prisma = response_prisma?.extended_response?.response[0];
                    //sstt_DDBB_prisma(response_prisma.INSTANCE + " - " + getFileName(response_prisma.FILENAME));
                    str_DDBB = "";
                    if (!isTest) {str_DDBB = response_prisma.INSTANCE + " - "}
                    str_DDBB += getFileName(response_prisma.FILENAME);
                    sstt_DDBB_prisma(str_DDBB)
                    }
                };
            fetch_DDBB_Data();
        }, []);

    const on_MenuClick = () => {
        let new_ctx_Flags = cloneDeep(ctx_Flags);
        new_ctx_Flags.drawer_open = !new_ctx_Flags.drawer_open;
        sctx_Flags(new_ctx_Flags);
        }

        //EN vez de usar lo siguiente para completar el estado stt_client_name, lo hago en ctx_User cuando se loguea el usuario 
        /*useEffect(() => {
        const load_client_name = async () => {
            //console.log("dentro de load_client_name");
            if (ctx_User && ctx_User.COD_CLTE) {
                const request_body_Cliente: iFormController_body = {
                    DB_table    : "TB_CLIENTES",
                    DB_action   : "select",
                    where       : { COD_CLTE: ctx_User.COD_CLTE },
                    }
                const response = await TryCatch_CLIENT_with_api('/api/form_controller', request_body_Cliente);
                const cliente: any = response.extended_response.response[0];
                if (cliente) {
                    sstt_client_name(cliente.NOMBRE_CLTE);
                    }
                
                } 
            };
        load_client_name();
        }, []); */


    const sx_wrapper_debug = {position: 'absolute', top: 0, left: 0, zIndex: 1000, display: 'flex', flexDirection: 'row', gap: '10px'}

    const sx_bbdd = {
        //position: 'absolute', left: 0, top: 0, //lo pongo arriba a la izquierda
        opacity: .6, pt:'2px', pl:'4px', fontSize:'.8rem'
        , textAlign: 'left', display: 'flex', flexDirection: 'row', gap: '10px'
        }
    const sx_vbles = {
        //position: 'absolute', top: 0, //right: "0%", //lo pongo arriba a la "mitad" para que esté cerca del resto (a la derecha estaba muy lejos)
        opacity: .8, pt:'2px', pr:'4px', fontSize:'.8rem'
        , textAlign: 'right', display: 'flex', flexDirection: 'row', gap: '10px'
        }
    const sx_vble = {
        borderLeft: "1px solid silver", pl:1, pr:1, 
        heigth: "100%!important", //no funciona
        }

    const sx_span = {display: "inline", ml:2}



    return (
            <Box 
                className = "LayoutClient"
                sx={{ 
                    display: 'flex', flexDirection: 'column', 
                    minHeight: '100vh',
                    boxSizing: 'border-box', //importante para que el padding no incremente el tamaño del contenedor
                    //backgroundColor: 'beige',
                    paddingTop: mui_theme.customized.appbar_height, // Ajusta este valor según la altura de tu AppBar para evitar que el contenido quede oculto
                    //paddingTop: '64px',
                    //hago lo necesario para que este valor esté disponible en global.scss
                    ':root': {
                             '--appbar-height': mui_theme.customized.appbar_height,
                             //'--theme-palette-primary-main': theme.palette.primary.main,
                             '--theme-palette-primary-main': theme.palette.error.main,
                             },
                    }}
                >
                <AppBar 
                    className="AppBar"
                    position="fixed" 
                    elevation={0} 
                    sx={{ 
                        flexShrink: 0, //El elemento no se encogerá cuando el contenedor sea más pequeño que el tamaño del contenido.
                                       // ¿quizas para disponisitivos moviles? ahora mismo no lo aprecio porque lo puse
                        borderBottom: mui_theme.customized.border_sections, 
                        height: mui_theme.customized.appbar_height,
                        //visibility: 'hidden'
                        }}
                    >
                    {/* habia pensado pasar el Toolbar a un componente independiente
                    pero inmplicaría pasarle por parametro estados y volver 
                    a importar otros componentes que ya se usan aqui
                    Ademas no se reutiliza en ningún sitio */}
                    <Toolbar
                        sx = {{
                            //border: "2px dotted beige"
                            }}
                        >
                        {isSmallScreen && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => on_MenuClick()}
                                >
                                <icons.Menu />
                            </IconButton>
                            )}
                        {/* legacyBehavior: para que no asigne estilos */}
                        <Link href="/" passHref  legacyBehavior >
                            {/* lo parentizo con <Box> porque sino el legacyBehavior provocaba un error al intentar abarcar 2 elementos */}
                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <Typography variant="h6" sx = {{color:"silver", pr:1}}>Admin</Typography>
                                <Typography variant="h6" >{ctx_User.NOMBRE_CLTE}</Typography> 
                            </Box>
                        </Link>

                        <Box sx={{ flexGrow: 1 }} />  {/* Caja flex para ocupar el espacio restante */}


                        {
                        status === 'authenticated' ? (
                                <>
                                    <Link href="/dashboard" passHref legacyBehavior>
                                        <Button 
                                            color="inherit"
                                            variant="outlined" 
                                            startIcon={<icons.Dashboard />}
                                            sx={{
                                                textTransform: 'none', // Esto evita que el texto esté en mayúsculas
                                                //borderWidth: 0,
                                                mr: 1,
                                            }}
                                            >
                                        Panel de Ctrl
                                        </Button>
                                    </Link>
                                    <Link href="/logout" passHref legacyBehavior>
                                        <Button 
                                            color="inherit"
                                            variant="outlined" 
                                            startIcon={<icons.Logout sx={{}}/>}
                                            sx={{
                                                textTransform: 'none', // Esto evita que el texto esté en mayúsculas
                                                //borderWidth: 0,
                                                '& .MuiButton-icon': {marginRight: '5px'},
                                                }}
                                            >
                                            ...salir
                                        </Button>
                                    </Link>
                                </>
                                ) 
                            : (
                                <>
                                {/* <Link href="/dashboard"><Typography sx = {{color:"white", mr:2}}>prueba dashboard</Typography></Link> */}
                                <Link href="/login" passHref legacyBehavior>
                                    <Button 
                                        color="inherit"
                                        variant="outlined" 
                                        endIcon={<icons.Login sx={{}}/>}
                                        sx={{
                                            textTransform: 'none', // Esto evita que el texto esté en mayúsculas
                                            //borderWidth: 0,
                                            '& .MuiButton-icon': {marginLeft: '5px'},
                                            }}
                                        >
                                        Entrar...
                                    </Button>
                                </Link>
                                </>
                                )
                        }
                        



                        {
                        (isDevelopmentDebug() || stt_isTest) && (
                            <>
                            <Box sx = {sx_wrapper_debug}>
                                {/* muestro la BBDD A LA QUE SE CONECTA, tanto desde sub_ryc como desde prisma */}
                                {(isDevelopmentDebug() || stt_isTest) && (
                                    <Box className = "bbdd" sx={sx_bbdd}>
                                        {process.env.NEXT_PUBLIC_URL} - {"(ryc: " + stt_DDBB_ryc + " ///// prisma: " + stt_DDBB_prisma + ")"} 
                                    </Box>
                                    )}
                                {/* muestro VBLES GENERALES */}
                                {(isDevelopmentDebug() ) && (
                                    <Box className = "vbles" sx={sx_vbles}>
                                    <Box sx={sx_vble}>
                                            <DebugBox 
                                                pData={ctx_User} 
                                                pLabel={'ctx_User'}
                                                pLabelColor='silver'
                                                pShow={true}
                                                pOnlyTooltip={true}
                                                pExcludedProps={["menuTree"]} //ya que los muestro en cada una de las ramas del propio componente y ashi es mas fácil de ver 
                                                pOpenMode={'click'}
                                                //pWidth={600}
                                                />
                                        </Box>
                                        <Box sx={sx_vble}>
                                            <DebugBox 
                                                pData={ctx_User.menuTree} 
                                                pLabel={'ctx_User.menuTree'}
                                                pLabelColor='silver'
                                                pShow={true}
                                                pOnlyTooltip={true}
                                                //pExcludedProps={["menuTree"]} //ya que los muestro en cada una de las ramas del propio componente y ashi es mas fácil de ver 
                                                pOpenMode={'click'}
                                                pWidth={650}
                                                />
                                        </Box>
                                        <Box sx={sx_vble}>
                                            <DebugBox 
                                                    pData={{ctx_User_tabsGeneral: ctx_User?.tabsGeneral, ctx_User_tabsAlojamientos: ctx_User?.tabsAlojamientos, ctx_User_tabsClub: ctx_User?.tabsClub, ctx_User_tabsEquipo: ctx_User?.tabsEquipo, ctx_User_tabsPerson: ctx_User?.tabsPerson}} 
                                                    pLabel={'ctx_User.tabs'}
                                                    pLabelColor='silver'
                                                    pShow={true}
                                                    pOnlyTooltip={true}
                                                    //pExcludedProps={["menuTree"]} 
                                                    pOpenMode={'click'}
                                                    //pWidth={400}
                                                    />                                
                                        </Box>
                                        <Box sx={sx_vble}>
                                            <DebugBox 
                                                    pData={ctx_Flags} 
                                                    pLabel={'ctx_Flags'}
                                                    pLabelColor='silver'
                                                    pShow={true}
                                                    pOnlyTooltip={true}
                                                    //pExcludedProps={["menuTree"]} 
                                                    pOpenMode={'click'}
                                                    //pWidth={400}
                                                    />
                                        </Box>
                                        <Box sx={sx_vble}>
                                            <DebugBox 
                                                    pData={session} 
                                                    pLabel={'session'}
                                                    pLabelColor='silver'
                                                    pShow={true}
                                                    pOnlyTooltip={true}
                                                    //pExcludedProps={["menuTree"]} 
                                                    pOpenMode={'click'}
                                                    //pWidth={400}
                                                    />
                                        </Box>
                                        <Box sx={sx_vble}>
                                            <DebugBox  
                                                    pData={status} 
                                                    pLabel={'status'}
                                                    pLabelColor='silver'
                                                    pShow={true}
                                                    pOnlyTooltip={true}
                                                    //pExcludedProps={["menuTree"]} 
                                                    pOpenMode={'click'}
                                                    //pWidth={400}
                                                    />
                                        </Box>
                                    </Box>
                                    )}
                            </Box>
                            </>
                            )
                        }
                    </Toolbar>
                </AppBar>
                {children}
            </Box>
        );
    };
